<template>
  <div>
    <page-header
      :background="require('@/assets/leagues/img.png')"
      title="Equipes"
    >
      <v-btn
        class="rounded-l-0 rounded-r-lg text-none"
        color="primary"
        to="/account/games"
      >
        <v-icon left>mdi-arrow-right</v-icon> Minhas Equipes
      </v-btn>
    </page-header>

    <v-container class="py-12">
      <div class="d-flex flex-column">
        <!-- loading -->
        <loader-content v-if="loading" class="my-12" />

        <!-- content -->
        <div v-else-if="teams.length > 0" class="my-12">
          <v-row justify="center" justify-md="start">
            <v-col
              v-for="(item, i) in teams"
              class="pb-12"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              :key="i"
            >
              <TeamCard :team="item" />
            </v-col>
          </v-row>
        </div>

        <!-- empty -->
        <div v-else class="d-flex flex-column align-center pb-12">
          <span class="text-body-1 text-center subtext--text">
            Nenhum resultado foi encontrado
          </span>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { getLeagues } from "@/services/leagues.js";
import TeamCard from "@/components/teams/TeamCard.vue";

export default {
  data() {
    return {
      loading: true,
      teams: [],
    };
  },

  components: {
    TeamCard,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const payload = {
          status: 0,
        };

        await getLeagues(payload).then((res) => {
          const teams = [];

          res.forEach((e) => {
            e.teams.forEach((j) => {
              teams.push({
                ...j,
                league: e.name,
                modality: e.x,
              });
            });
          });

          this.teams = teams;
        });
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
