<template>
  <v-card
    class="rounded-lg zoom"
    color="surface"
    :to="`/team-details/${team.id}`"
  >
    <v-img :src="team.logo" :aspect-ratio="4 / 4" class="rounded-t-lg accent" />

    <div class="background-gradient d-flex flex-column align-center pa-2">
      <span class="text-h6">
        <span class="saira">{{ team.name }} [{{ team.tag }}]</span>
      </span>
      <span class="text-caption subtext--text">
        {{ team.league }} (x{{ team.modality }})
      </span>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.background-gradient {
  background: radial-gradient(
    152.6% 32781.24% at 100% 50%,
    #1f2125 0%,
    rgba(31, 33, 37, 0) 100%
  );
}
</style>
